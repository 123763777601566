export const constantVariables = {
  accessToken: '_at',
  refreshToken: '_rt',
  selectedProduct: '_sp',
};
export const staticImagesRoute = {
  tzLayerImage1: 'https://media.thumb.zone/statics/layers/layer1.png',
  tzLayerImage2: 'https://media.thumb.zone/statics/layers/layer2.png',
  tzLayerImage3: 'https://media.thumb.zone/statics/layers/layer3.png',
  tzLayerImage4: 'https://media.thumb.zone/statics/layers/layer4.png',
  tzLayerImage5: 'https://media.thumb.zone/statics/layers/layer5.png',
};
export const STAGE_WIDTH = 263;
export const STAGE_HEIGHT = 570;
